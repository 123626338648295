import React from "react";
import PropTypes from "prop-types";
import Hero from "./hero";
import BodyText from "./bodytext";

const Page = props => {

  const {
    page: {
      html,
      frontmatter: {title}
    }
  } = props;

  return (
    <React.Fragment>
      <Hero header={title}/>
      <BodyText html={html}/>
    </React.Fragment>
  );
};

Page.propTypes = {
  page: PropTypes.object.isRequired
};

export default Page;
