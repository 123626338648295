import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import Page from "../components/page";
import Seo from "../components/seo";
import Layout from "../components/layout";
import "./pageTemplate.scss";

const PageTemplate = props => {
  const {
    data: {
      page
    }
  } = props;

  return (
    <React.Fragment>
      <Layout page={page}>
        <Page page={page}/>
      </Layout>
      <Seo data={page}/>
    </React.Fragment>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageByPath($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
