import React from "react";
import PropTypes from "prop-types";
import {Container, GridColumn, GridRow} from "semantic-ui-react";
import "./bodytext.scss";

const BodyText = props => {
  const {html} = props;

  return (
    <Container fluid>
      <GridRow columns={1}>
        <GridColumn>
          <div className={"body-text"} dangerouslySetInnerHTML={{__html: html}}/>
        </GridColumn>
      </GridRow>
    </Container>
  );
};

BodyText.propTypes = {
  html: PropTypes.string.isRequired
};

export default BodyText;
